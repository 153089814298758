<template>
  <!-- Start Header V3 -->
  <section class="home demo3" id="home">
    <div class="header-content container">
      <div class="row">
        <div class="col-md-6">
          <div class="home-content">
            <div>
              <h5 class="fix-title">Welcome To Pranayama</h5>
              <h1>Balance Body &amp; Mind</h1>
              <p>yoga doesn't take time it gives time.</p>
              <div class="my-btn">
                <router-link to="/services" target="_blank" class="main-btn"><span>Learn More</span></router-link>
                <router-link to="/contact-us" target="_blank" class="main-btn custom-btn"><span>Contact Us</span></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <div class="home-image">
            <img src="../assets/images/about.png" alt />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--End Header V3 -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>
